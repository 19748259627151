import { AtomicNetworkApplications, AtomicNetworkSpotlights, OrganizationConfig } from "@/types/home";
import axios, { AxiosError, AxiosInstance, AxiosPromise } from "axios";

const assetClient = axios.create({
	baseURL: process.env.VUE_APP_ASSET_SERVER_URL || "https://127.0.0.1:3000",
	timeout: 10000 /* 10 seconds */,
});

export function getAssetByPath<T>(assetPath: string): AxiosPromise<T> {
	return assetClient.get(assetPath);
}

export function getSpotlightsByNetwork(networkName: string): AxiosPromise<AtomicNetworkSpotlights> {
	const assetPath = `/content/home/spotlights/${networkName.toLowerCase()}.json`;
	return getAssetByPath<AtomicNetworkSpotlights>(assetPath);
}

export function getApplicationsByNetwork(networkName: string): AxiosPromise<AtomicNetworkApplications> {
	const assetPath = `/content/home/applications/${networkName.toLowerCase()}.json`;
	return getAssetByPath<AtomicNetworkApplications>(assetPath);
}

export function getOrganizationConfig(organization: string): AxiosPromise<Partial<OrganizationConfig>> {
	const assetPath = `/organizations/${organization.toLowerCase()}/config.json`;
	return getAssetByPath<Partial<OrganizationConfig>>(assetPath);
}
