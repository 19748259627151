// Libraries
import { Core } from "@/types/api/cores";
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import {
	CreateCore201Response,
	EditCore200Response,
	GetCore200Response,
	ListCores200Response,
} from "../signaloidClient.types";

/**
 * Get all the default cores
 *
 * @returns {Promise<AxiosResponse<ListCores200Response>>} A Promise that resolves to an object containing
 * a list of core objects for each default core the user has access to.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/ListCores
 *
 */
export function getDefaultCores(): Promise<AxiosResponse<ListCores200Response>> {
	return signaloidApiClient.get(`/cores?default`);
}
/**
 * Get all the user's cores
 *
 * @returns {Promise<AxiosResponse<ListCores200Response>>} A Promise that resolves to an object containing
 * a list of core objects for each core a user has. (Does not include the default cores.)
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/ListCores
 *
 */
export function getUserCores(): Promise<AxiosResponse<ListCores200Response>> {
	return signaloidApiClient.get(`/cores`);
}

/**
 * Get a user's core by coreID.
 *
 * @param {string} coreID The identifier of the core.
 * @returns {Promise<AxiosResponse<GetCore200Response>>} A promise that resolves to an object with the core details.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/GetCore
 */
export function getCoreByID(coreID: string): Promise<AxiosResponse<GetCore200Response>> {
	return signaloidApiClient.get(`/cores/${coreID}`);
}

/**
 * Create a new core for the user.
 *
 * @param {Core} coreConfig The configuration of the core to be created.
 * @returns {Promise<AxiosResponse<CreateCore201Response>>} A promise that resolves to a "200 OK" response if
 * core is successfully created along with a core object with the core data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/CreateCore
 */
export function createCore(coreConfig: Partial<Core>): Promise<AxiosResponse<CreateCore201Response>> {
	return signaloidApiClient.post(`/cores`, coreConfig);
}

/**
 * Edit a user's core by coreID.
 *
 * @param {string} coreID
 * @param {Core} coreConfig
 * @returns {Promise<AxiosResponse<EditCore200Response>>} Promise that resolves to a "200 OK" response if
 * core is successfully updated along with a core object with the core data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/UpdateCore
 */
export function editCoreByID(coreID: string, coreConfig: Partial<Core>): Promise<AxiosResponse<EditCore200Response>> {
	return signaloidApiClient.patch(`/cores/${coreID}`, coreConfig);
}

/**
 * Delete a user's core by coreID.
 *
 * @param {string} coreID
 * @returns {Promise<AxiosResponse<void>>} The axios response promise.
 * If sucessful, the body resolves to:
 * ```json
 * { "message": "OK" }
 * ```
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Cores/operation/DeleteCore
 */
export function deleteCoreByID(coreID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.delete(`/cores/${coreID}`);
}
