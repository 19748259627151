// Libraries
import { User, UserCustomization, UserPreferences } from "@/types/user";
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";

/**
 * Get the user's profile
 *
 * @param {string} userID The ID of the user.
 * @returns {UserPreferences} The user's profile.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/User/operation/GetUser
 */
// TODO: Better type for the return value
// TODO: Fix the API docs header
export function getUserByID(userID: string): Promise<AxiosResponse<User>> {
	return signaloidApiClient.get(`/users/${userID}`);
}

/**
 * Update the user's preferences / profile.
 *
 * @param {string} userID The ID of the user.
 * @param {UserPreferences} newPreferences The new preferences to set.
 * @returns An axios response that translates to a "200 OK" if the operation was successful.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/User/operation/UpdateUserPreferences
 */
export function updateUserPreferences(
	userID: string,
	newPreferences: Partial<UserPreferences>
): Promise<AxiosResponse<{Preferences: UserPreferences}>> {
	const payload = {
		Preferences: newPreferences,
	};
	return signaloidApiClient.patch(`/users/${userID}`, payload);
}

/**
 * Delete request for user's account/profile.
 *
 * @param {string} userID The ID of the user.
 * @returns An axios response that translates to a "200 OK" if the operation was successful.
 */
export function deleteUserProfile(userID: string) {
	return signaloidApiClient.delete(`/users/${userID}`);
}

export function getUserCustomizations(userID: string): Promise<AxiosResponse<UserCustomization>> {
	return signaloidApiClient.get(`/users/${userID}/customization`);
}
