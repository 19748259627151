
export const TaskStatusE = {
	Initialising: "Initialising",
	Accepted: "Accepted",
	InProgress: "In Progress",
	Completed: "Completed",
	Cancelled: "Cancelled",
	Stopped: "Stopped",
	Rescheduled: "Rescheduled",
	NotFoundError: "Task Not Found",
	Forbidden: "Task Rejected",
	Error: "Error",
	UpgradeAccount: "Upgrade Your Account",
	TierLimited: "Tier Limit Reached",
} as const;
export type TaskStatus = (typeof TaskStatusE)[keyof typeof TaskStatusE];

export type TaskStatusTransition = {
	Status: TaskStatus;
	Timestamp: number;
	Message?: string;
};

export function isTaskActive(taskStatus: TaskStatus): boolean {
	const activeStatuses = [
		TaskStatusE.Accepted,
		TaskStatusE.Initialising,
		TaskStatusE.Rescheduled,
		TaskStatusE.InProgress,
	];
	return activeStatuses.some((s) => s === taskStatus);
}

export function isTaskTerminated(taskStatus: TaskStatus): boolean {
	const activeStatuses = [TaskStatusE.Completed, TaskStatusE.Cancelled, TaskStatusE.Stopped];
	return activeStatuses.some((s) => s === taskStatus);
}

export function isTaskSuccess(taskStatus: TaskStatus): boolean {
	const activeStatuses = [TaskStatusE.Completed];
	return activeStatuses.some((s) => s === taskStatus);
}
export function isTaskFail(taskStatus: TaskStatus): boolean {
	const activeStatuses = [
		TaskStatusE.Cancelled,
		TaskStatusE.Stopped,
	];
	return activeStatuses.some((s) => s === taskStatus);
}



/*
 * Response types
 */

export type TaskStatistics = {
	DynamicInstructions: number;
	ProcessorTime: number;
};

export type Task = {
	TaskID: string;
	Owner: string;
	BuildID: string;
	Arguments: string;
	Status: TaskStatus;
	StatusTransitions: TaskStatusTransition[];
	Stats?: TaskStatistics;
	StartedAt: number /* The time the Task.Status becomes "In Progress" */;
	UpdatedAt: number;
	CreatedAt: number /* The time the Task.Status becomes "Accepted" */;
	FinishedAt: number /* The time the Task.Status becomes "Completed", "Cancelled", or "Stopped" */;
};

export type TaskWithStats = {
	TaskID: string;
	Owner: string;
	BuildID: string;
	Status: TaskStatus;
	StatusTransitions: TaskStatusTransition[];
	Stats?: TaskStatistics;
	StartedAt: number /* The time the Task.Status becomes "In Progress" */;
	UpdatedAt: number;
	CreatedAt: number /* The time the Task.Status becomes "Accepted" */;
	FinishedAt: number /* The time the Task.Status becomes "Completed", "Cancelled", or "Stopped" */;
};


export const TaskOutputStreamsE = {
	stdout: "stdout",
	stderr: "stderr",
} as const;
export type TaskOutputStreams = (typeof TaskOutputStreamsE)[keyof typeof TaskOutputStreamsE];

// FIXME: how can i make this dynamic from TaskOutputStreams
export type TaskOutput = {
	[key: string]: string;
};
