import { defineStore } from "pinia";
import * as signaloidClient from "@/js/signaloidClient";
import axios from "axios";
import { isTaskActive, isTaskTerminated, Task, TaskStatus } from "@/types/api/tasks";
import { useEventBus, UseEventBusReturn } from "@vueuse/core";
import * as Sentry from "@sentry/vue";

import type { EventBusKey } from "@vueuse/core";
import { RemoteAccessState, TaskBusEvent, TaskEvent, TaskEventE } from "@/types/general";
import { useUserStore } from "./user";

export const taskKey: EventBusKey<TaskBusEvent> = Symbol("symbol-key");
type TaskSubscription = { taskID: string; taskData: any; retryCount: number };

import * as util from "@/js/utilities";
import { UserLimitsE } from "@/js/tierconfig";
import { TierLimitEventTypeE } from "@/eventBus/tierLimitEventBus";
import { useRootStore } from "@/stores/root";
import { monitoringCaptureError } from "@/plugins/monitoring";
import { PartialTask } from "@/js/signaloidClient.types";

type StoreState = {
	taskHistory: undefined | Task[];
	activeTasks: undefined | Task[];
	taskHistoryUpdateStatus: RemoteAccessState;
	activeTaskUpdateStatus: RemoteAccessState;
	taskMetadataHistory: undefined | PartialTask[];
	subscribedTasks: TaskSubscription[];
	taskEventBus: UseEventBusReturn<TaskBusEvent, any>;
	taskListContinuationKey: undefined | string;
	activeTasksListContinuationKey: undefined | string;
};

/*
 * given n = tasks returned per request, the SCDP will try to look for
 * active tasks in the first (n * activeTaskMaxFetchDepth) tasks in the databse
 * at the time of writing n = 50
 */
const activeTaskMaxFetchDepth = 3;

export const useTasksStore = defineStore("tasks", {
	state: (): StoreState => ({
		taskHistory: undefined,
		activeTasks: [],
		taskHistoryUpdateStatus: { loading: false, error: false, message: "" },
		activeTaskUpdateStatus: { loading: false, error: false, message: "" },
		taskListContinuationKey: undefined,
		activeTasksListContinuationKey: undefined,
		taskMetadataHistory: undefined,
		subscribedTasks: [],
		taskEventBus: useEventBus(taskKey),
	}),
	getters: {
		getTaskByProjectID: (state) => (projectID: string) => {
			// ! Deprecated
			// console.warn("using deprecated function (getTaskByProjectID) :>> ");
			if (!state.taskHistory) {
				return undefined;
			}
			return state.taskHistory.find((x) => x.TaskID === projectID) ?? null;
		},
		getTaskByID: (state) => (taskID: string) => {
			if (!state.taskHistory || !taskID) {
				return undefined;
			}
			return state.taskHistory.find((x) => x.TaskID === taskID) ?? null;
		},
		inactiveTasks(): Task[] {
			if (!this.taskHistory) {
				return [];
			}

			return this.taskHistory.filter((x) => !isTaskActive(x.Status));
		},
	},
	actions: {
		updateTaskByID(taskData: Task) {
			if (this.taskHistory) {
				this.taskHistory.splice(
					this.taskHistory.findIndex((x) => x.TaskID === taskData.TaskID),
					1,
					taskData
				);
				//FIXME: This is wasteful
				this.taskHistory.sort((a, b) => {
					return b.CreatedAt - a.CreatedAt;
				});
			}
			if (this.activeTasks) {
				this.activeTasks.splice(
					this.activeTasks.findIndex((x) => x.TaskID === taskData.TaskID),
					1,
					taskData
				);
			}
		},
		removeTaskByIdFromLocal(taskID: string) {
			if (!this.taskHistory) {
				return;
			}
			const newTaskHistory = this.taskHistory.filter((x) => x.TaskID != taskID);
			this.taskHistory = newTaskHistory;
		},
		updateTaskList(taskData: Task) {
			// ! deprecated
			// console.warn("using deprecated function (updateTaskList) :>> ");
			if (this.taskHistory) {
				this.taskHistory.splice(
					this.taskHistory.findIndex((x) => x.TaskID === taskData.TaskID),
					1,
					taskData
				);
			}
		},
		removeFromActiveTaskList(taskID: string) {
			if (this.activeTasks) {
				const activeTaskIndex = this.activeTasks.findIndex((x) => x.TaskID === taskID);
				if (activeTaskIndex !== -1) {
					this.activeTasks.splice(activeTaskIndex, 1);
				}
			}
		},
		addToActiveTaskList(taskData: Task) {
			this.activeTasks?.push(taskData);
		},
		subscribeToActiveTasks() {
			if (this.activeTasks) {
				this.activeTasks.forEach((task) => {
					const subscribedTaskIndex = this.subscribedTasks.findIndex((x) => x.taskID === task.TaskID);
					if (subscribedTaskIndex == -1) {
						// No sub for this task
						this.subscribeToTaskV2(task.TaskID);
					}
				});
			}
		},
		async initialiseTaskHistory() {
			// Remove all tasks from local storage
			localStorage.removeItem("task-list");
			this.taskListContinuationKey = undefined;
			this.activeTasksListContinuationKey = undefined;
			await this.fetchNextTaskBatchFromRemote();
			await this.fetchActiveTasksFromRemote();
		},

		async fetchActiveTasksFromRemote() {
			try {
				this.activeTaskUpdateStatus.loading = true;
				let requestCounter = 0;
				do {
					await this.fetchNextActiveTaskBatchFromRemote();
					requestCounter += 1;
				} while (requestCounter < activeTaskMaxFetchDepth && this.activeTasksListContinuationKey !== undefined);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 404) {
						// No tasks found. set the history to be empty
						this.activeTasks = [];
					}
				}
			} finally {
				this.activeTaskUpdateStatus.loading = false;
				this.subscribeToActiveTasks();
			}
		},
		async fetchNextActiveTaskBatchFromRemote(triggeredByUser: boolean = false) {
			try {
				// this.activeTaskUpdateStatus.loading = true; //FIXME: Enable when iterative fetching is enabled
				const response = await this.fetchTasksFromRemote(this.activeTasksListContinuationKey, [
					"Accepted",
					"Initialising",
					"In Progress",
				]);

				if (response.data.Tasks) {
					this.activeTasks =
						this.activeTasks && this.taskListContinuationKey
							? this.activeTasks.concat(response.data.Tasks)
							: response.data.Tasks;

					this.activeTasksListContinuationKey = response.data.ContinuationKey;
				}
			} catch (error) {
				let isForbiddenError = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						//non 2xx response
						if (error.response.status === 404) {
							// No tasks found. set the history to be empty
							this.activeTasks = [];
						} else if (error.response.status === 403 || error.response.status === 402) {
							/*
							 * if the activeTasks is not undefined and there was a 403, this means that the
							 * user tried to fetch more tasks that they are allowed to
							 */
							isForbiddenError = true;
							if (this.activeTasks !== undefined && triggeredByUser) {
								const rootStore = useRootStore();
								rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.TaskHistoryLength],
								});
							}
						}
					}
				}
				// Do not send 403 forbidden errors to sentry.
				if (!isForbiddenError) {
					monitoringCaptureError(error, "Get active tasks from remote");
				}
			} finally {
				// this.activeTaskUpdateStatus.loading = false; //FIXME: Enable when iterative fetching is enabled
			}
		},

		async fetchNextTaskBatchFromRemote(triggeredByUser: boolean = false) {
			try {
				this.taskHistoryUpdateStatus.loading = true;
				const response = await this.fetchTasksFromRemote(this.taskListContinuationKey);
				if (response.data.Tasks) {
					this.taskHistory =
						this.taskHistory && this.taskListContinuationKey
							? this.taskHistory.concat(response.data.Tasks)
							: response.data.Tasks;
					this.taskListContinuationKey = response.data.ContinuationKey;

					//FIXME: This is wasteful
					this.taskHistory.sort((a, b) => {
						return b.CreatedAt - a.CreatedAt;
					});
				}
			} catch (error) {
				let isForbiddenError = false;

				if (axios.isAxiosError(error)) {
					if (error.response) {
						//non 2xx response

						if (error.response.status === 404) {
							// No tasks found. set the history to be empty
							this.taskHistory = [];
						} else if (error.response.status === 403 || error.response.status === 402) {
							/*
							 * if the taskHistory is not undefined and there was a 403, this means that the
							 * user tried to fetch more tasks that they are allowed to
							 */
							isForbiddenError = true;
							if (this.taskHistory !== undefined && triggeredByUser) {
								const rootStore = useRootStore();
								rootStore.tierLimitEventBus.emit({
									type: TierLimitEventTypeE.LimitExceeded,
									affectedLimits: [UserLimitsE.TaskHistoryLength],
								});
							}
						}
					}
				}
				// Do not send 403 forbidden errors to sentry.
				if (!isForbiddenError) {
					monitoringCaptureError(error, "Get tasks from remote");
				}
			} finally {
				this.taskHistoryUpdateStatus.loading = false;
			}
		},

		async fetchTasksFromRemote(startKey?: string, taskStatus?: TaskStatus[]) {
			const authUserStore = useUserStore();
			await util.waitFor(() => authUserStore.currentUserSessionObject !== undefined);
			try {
				const response = await signaloidClient.getUserTasks(startKey, taskStatus);
				return response;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getTasksMetadataFromRemote(
			from?: string,
			to?: string,
			startKey?: string
		): Promise<{ PartialTasks: PartialTask[]; ContinuationKey?: string } | null> {
			const authUserStore = useUserStore();
			await util.waitFor(() => authUserStore.currentUserSessionObject !== undefined);
			try {
				const response = await signaloidClient.getUserTasksMetadata(from, to, startKey);
				return {
					PartialTasks: response.data.Tasks,
					ContinuationKey: response.data.ContinuationKey,
				};
			} catch (error) {
				Sentry.captureException(error);
				if (axios.isAxiosError(error)) {
					if (error.response) {
						//non 2xx response
						console.log("Non 2xx response:", error.response);
					} else if (error.request) {
						//no response
						console.log("Request Failed:", error.request);
					} else {
						//making request failed
						console.log("Preflight Error", error);
					}
				}
				return null;
			}
		},
		async getTasksMetadataHistoryWithinDays(days: number = 30): Promise<PartialTask[]> {
			// Make sure we got a token
			const authUserStore = useUserStore();
			await util.waitFor(() => authUserStore.currentUserSessionObject !== undefined);

			// Limit date to filter
			const today = new Date();
			const priorDate = new Date(today.setDate(today.getDate() - days));
			const from = priorDate.toISOString();
			const partialTasks: PartialTask[] = [];

			let startKey: string | undefined = undefined;
			// Get via pagination all tasks up until x days ago.
			do {
				const partialTasksResponse = await this.getTasksMetadataFromRemote(from, undefined, startKey);
				if (!partialTasksResponse) {
					break;
				}
				if (partialTasksResponse.PartialTasks.length === 0) {
					startKey = undefined;
					continue;
				}
				partialTasks.push(...partialTasksResponse.PartialTasks);
				startKey = partialTasksResponse.ContinuationKey;
			} while (startKey !== undefined);

			this.taskMetadataHistory = partialTasks;
			return partialTasks;
		},
	
		// async fetchAndParseVariables(taskID: string) {
		// 	let discoveredVariables: TraceVariableWithTraceType[] = [];
		// 	try {
		// 		const response = await signaloidClient.getTaskVariables(taskID);
		// 		if (response.data.Variables) {
		// 			discoveredVariables =
		// 				response.data["Variables"].map((variableTrace): TraceVariableWithTraceType => {
		// 					return {
		// 						Expression: variableTrace.Name,
		// 						File: variableTrace.File,
		// 						LineNumber: variableTrace.Line,
		// 						Type: variableTrace.Type,
		// 					};
		// 				}) ?? [];
		// 		}
		// 	} catch (error) {
		// 		monitoringCaptureError(error, "Parse task variables");
		// 	}
		// 	return discoveredVariables;
		// },
		async subscribeToTaskV2(taskID: string) {
			const subscribedTaskIndex = this.subscribedTasks.findIndex((x) => x.taskID === taskID);
			if (subscribedTaskIndex != -1) {
				return;
			}
			// Add to subscribed task list
			this.subscribedTasks.push({ taskID: taskID, taskData: null, retryCount: 0 });

			//Make the first call
			const firstCallStatus = await this.getTaskUpdate(taskID);

			// Limiter
			const maxErrorRetries: number = 3;
			const errorDelayIncreaseCoefficient: number = 2;

			const maxRetryDelayInMilliseconds: number = 6000;
			const delayIncreaseCoefficient: number = 1.1;
			const defaultRetryDelayInMilliseconds: number = 500;

			let lastStatus = firstCallStatus;
			let errorRetry: number = 0;
			let retryDelayInMilliseconds: number = defaultRetryDelayInMilliseconds;

			while (lastStatus!="Error" && !isTaskTerminated(lastStatus as TaskStatus) || errorRetry >= maxErrorRetries) {
				await new Promise((resolve) => setTimeout(resolve, retryDelayInMilliseconds));

				const callStatus = await this.getTaskUpdate(taskID);

				const isError = callStatus == "Error";
				const statusChanged = callStatus !== lastStatus;

				if (statusChanged) {
					//Reset retry delay to default
					retryDelayInMilliseconds = defaultRetryDelayInMilliseconds;
				} else {
					const coefficient = isError ? errorDelayIncreaseCoefficient : delayIncreaseCoefficient;
					const increasedDelay = retryDelayInMilliseconds * coefficient;
					retryDelayInMilliseconds = Math.min(increasedDelay, maxRetryDelayInMilliseconds);
				}
				// Update status for next iteration
				if (isError) {
					errorRetry += 1;
				}
				lastStatus = callStatus;
			}
		},
		async getTaskUpdate(taskID: string): Promise<string> {
			try {
				// get task data from API
				const response = await signaloidClient.getTaskByID(taskID);
				this.handleTaskUpdates(response.data);

				return response.data.Status;
			} catch (error) {
				Sentry.captureException(error);
				return "Error";
			}
		},
		handleTaskUpdates(taskData: Task) {
			// update the task object in task history
			this.updateTaskByID(taskData);
			const subscribedTaskIndex = this.subscribedTasks.findIndex((x) => x.taskID === taskData.TaskID);

			// Check if task is terminated/completed
			if (isTaskTerminated(taskData.Status)) {
				this.subscribedTasks.splice(subscribedTaskIndex, 1);
				this.removeFromActiveTaskList(taskData.TaskID);

				this.taskEventBus.emit({ type: TaskEventE.TaskCompleted, taskData: taskData });

				const rootStore = useRootStore();
				rootStore.tierLimitEventBus.emit({
					type: TierLimitEventTypeE.UsageChanged,
					affectedLimits: [UserLimitsE.ConcurrentTaskCount, UserLimitsE.DynamicInstructionCount],
				});
				// Update histogram
				this.getTasksMetadataHistoryWithinDays();
				return;
			}
			// Update status of tasks of ongoing tasks
			this.taskEventBus.emit({ type: TaskEventE.TaskUpdated, taskData: taskData });
		},
	},
});
