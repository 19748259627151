import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

// const signaloidGrey = '#333333'
const signaloidRed = "#ff4d41";
const signaloidGreen = "#33A333";
const signaloidYellow = "#F8DD27";

export default new Vuetify({
	breakpoint: {
		mobileBreakpoint: "xs",
	},
	theme: {
		// dark: true,
		themes: {
			light: {
				primary: colors.blue.darken1,
				secondary: signaloidRed,
				accent: signaloidGreen,
				error: "#b71c1c",
				anchor: colors.blue.darken1,
				danger: signaloidRed,
				inactive: colors.blueGrey.lighten2,
			},
			dark: {
				primary: colors.blue.darken1,
				secondary: signaloidRed,
				accent: signaloidGreen,
				error: "#b71c1c",
				anchor: colors.blue.darken1,
				danger: signaloidRed,
				inactive: colors.blueGrey.lighten2,
			},
		},
		options: {
			customProperties: true,
		},
	},
	buttons: {
		capitalize: false,
	},
});
