import { SourceCodeLanguageObject } from "@/types/api/sourceCode";
import * as Sentry from "@sentry/vue";

import {
	getApplicationsByNetwork,
	getAssetByPath,
	getOrganizationConfig,
	getSpotlightsByNetwork,
} from "@/js/assetsClient";
import axios from "axios";
import { defineStore } from "pinia";
import { useUserStore } from "./user";
import * as util from "@/js/utilities";
import {
	ApplicationSlideInfo,
	AtomicNetworkApplications,
	AtomicNetworkSpotlights,
	OrganizationConfig,
	SpotlightSlideInfo,
} from "@/types/home";

import { updateBackendClientBaseURL } from "@/js/signaloidClient";
import { monitoringCaptureError } from "@/plugins/monitoring";

// tier limit event bus config
import { useEventBus, UseEventBusReturn } from "@vueuse/core";
import { TierLimitBusEvent, tierLimitEventKey } from "@/eventBus/tierLimitEventBus";
import { PlatformAuthState, PlatformAuthStateE, PlatformGlobalState, PlatformGlobalStateE } from "@/types/general";

const defaultApplicationsList = require("@/assets/Home/applications/_default.json") as AtomicNetworkApplications;
const defaultSpotlightsList = require("@/assets/Home/spotlights/_default.json") as AtomicNetworkSpotlights;
const defaultPlatformLogo = require("@/assets/images/signaloid-logo-v4.0-white-green-minimal-0pxPadding-160.png");
// TODO: might be worth using an URL object instead

type StoreState = {
	backendURL: undefined | string;
	codeLanguagesList: SourceCodeLanguageObject[];
	platformLogo: undefined | string;
	applicationsList: ApplicationSlideInfo[];
	spotlightList: SpotlightSlideInfo[];
	organizationConfig: undefined | null | Partial<OrganizationConfig>;
	tierLimitEventBus: UseEventBusReturn<TierLimitBusEvent, any>;
	userflowLoaded: boolean;
	showHelpButton: boolean;
	platformNetworkOnline: boolean;
};

export const useRootStore = defineStore("root", {
	state: (): StoreState => ({
		backendURL: undefined,
		codeLanguagesList: [],
		platformLogo: undefined,
		applicationsList: defaultApplicationsList,
		spotlightList: defaultSpotlightsList,
		organizationConfig: undefined as undefined | Partial<OrganizationConfig>,
		tierLimitEventBus: useEventBus(tierLimitEventKey),
		userflowLoaded: false,
		showHelpButton: false,
		platformNetworkOnline: true,
	}),
	getters: {
		platformAuthState(state): PlatformAuthState {
			const authUserStore = useUserStore();

			// If there is no user object, we should initialise
			if (authUserStore.currentAuthenticatedUserObject === undefined) {
				return PlatformAuthStateE.InitialisingAuthUser;
			}

			// If the user is explicitly null that means there is no active user
			if (authUserStore.currentAuthenticatedUserObject === null) {
				return PlatformAuthStateE.NoAuthUser;
			}

			/*
			 * At this point `authUserStore.currentAuthenticatedUserObject` exists
			 */

			// If there is no subscriptions object, we should initialise
			if (authUserStore.currentUserSubscriptions === undefined) {
				return PlatformAuthStateE.InitialisingUserSubscriptions;
			}

			// Check if the user has a valid subscription
			if (
				authUserStore.currentUserSubscriptions === null ||
				authUserStore.currentUserSubscriptions.Tier === undefined ||
				authUserStore.currentUserSubscriptions.Tier === null ||
				authUserStore.currentUserSubscriptions.Tier === "NO_TIER"
			) {
				return PlatformAuthStateE.NoUserSubscription;
			}

			return PlatformAuthStateE.Signedin;

			// return PlatformAuthStateE.Error;
		},
		platformGlobalState(state): PlatformGlobalState {
			const authUserStore = useUserStore();

			if (this.platformAuthState === PlatformAuthStateE.NoAuthUser) {
				return PlatformGlobalStateE.WaitingForLogin;
			}

			/*
			 * User has logged in, but no subscription
			 * We have basic user auth details. if the current user has no tier, start onboarding flow
			 */

			if (this.platformAuthState === PlatformAuthStateE.NoUserSubscription) {
				return PlatformGlobalStateE.OnboardingMode;
			}

			// User has subscription, should be ok to fetch user data
			// waiting for basic user data
			if (!authUserStore.currentUserObjectFromDB) {
				return PlatformGlobalStateE.InitialisingUserAuth;
			}

			return PlatformGlobalStateE.Ready;
		},
	},
	actions: {
		async fetchOrganizationConfigFromRemote() {
			const authUserStore = useUserStore();
			// wait for user org details to be fetched
			const currentUserPrimaryOrganization = await authUserStore.getCurrentUserPrimaryOrganization();

			if (!currentUserPrimaryOrganization) {
				this.organizationConfig = null;
				return;
			}

			try {
				const response = await getOrganizationConfig(currentUserPrimaryOrganization);
				this.organizationConfig = response.data;
			} catch (error) {
				this.organizationConfig = null;
				monitoringCaptureError(error, "Fetch Organization Customization Config");
			}
		},

		async getOrganizationConfig() {
			if (this.organizationConfig === undefined) {
				this.fetchOrganizationConfigFromRemote();
			}
			return new Promise<null | Partial<OrganizationConfig>>((resolve, reject) => {
				const loop = () =>
					this.organizationConfig !== undefined ? resolve(this.organizationConfig) : setTimeout(loop);
				loop();
			});
		},
		async updateComputeEngineHost() {
			// wait for user org details to be fetched
			const organizationConfig = await this.getOrganizationConfig();

			if (organizationConfig?.computeEngineHost) {
				this.backendURL = organizationConfig?.computeEngineHost;
			} else {
				this.backendURL = process.env.VUE_APP_BACKEND_API_URL || "https://127.0.0.1:3000";
			}
			updateBackendClientBaseURL(this.backendURL);
		},
		async updatePlatformLogo() {
			// wait for user org details to be fetched
			const organizationConfig = await this.getOrganizationConfig();

			if (organizationConfig?.logoURL) {
				// if Org is defined get org config and logo from asset server
				this.platformLogo = new URL(organizationConfig.logoURL, process.env.VUE_APP_ASSET_SERVER_URL).href;
			} else {
				this.platformLogo = defaultPlatformLogo;
			}
		},
		async updatePlatformSpotlightSlides() {
			const authUserStore = useUserStore();

			let spotlightListResponse: Awaited<ReturnType<typeof getSpotlightsByNetwork>>;
			let unvalidatedSpotlightList;

			// wait for user org details to be fetched
			const organizationConfig = await this.getOrganizationConfig();

			// separate try catch blocks because we want to be try other things if one fetch fails
			// If org is defined try to get organization spotlights
			if (organizationConfig?.spotlightsList) {
				try {
					spotlightListResponse = await getAssetByPath(organizationConfig?.spotlightsList);
					unvalidatedSpotlightList = spotlightListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Spotlight Slide Config");
				}
			}

			// if org not defined or org spotlights not defined,
			// but atomic network is defined try to get atomic network spotlights
			const currentUserPrimaryAtomicNetwork = await authUserStore.getCurrentUserPrimaryAtomicNetwork();

			if (
				(!unvalidatedSpotlightList || unvalidatedSpotlightList?.length == 0) &&
				currentUserPrimaryAtomicNetwork
			) {
				try {
					spotlightListResponse = await getSpotlightsByNetwork(currentUserPrimaryAtomicNetwork);
					unvalidatedSpotlightList = spotlightListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Spotlight Slide Config");
				}
			}

			// if we still dont have a spotlight list, try to fetch the default list
			if (!unvalidatedSpotlightList || unvalidatedSpotlightList?.length == 0) {
				try {
					spotlightListResponse = await getSpotlightsByNetwork("default");
					unvalidatedSpotlightList = spotlightListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Spotlight Slide Config");
				}
			}

			// Validate the spotlights returned from the assets server
			if (unvalidatedSpotlightList?.length > 0) {
				const validatedSpotlightsList = unvalidatedSpotlightList.filter(
					(spotlightEntryFromRemote: Partial<SpotlightSlideInfo>, idx: number) => {
						return this.validSpotlightEntry(spotlightEntryFromRemote);
					}
				);
				if (validatedSpotlightsList.length > 0) {
					this.spotlightList = validatedSpotlightsList;
				}
			} else {
				this.spotlightList = defaultSpotlightsList;
			}
		},
		async updatePlatformApplicationHighlights() {
			const authUserStore = useUserStore();

			let applicationsListResponse: Awaited<ReturnType<typeof getApplicationsByNetwork>>;
			let unvalidatedApplicationsList;

			// separate try catch blocks because we want to be try other things if one fetch fails

			// wait for user org details to be fetched
			const organizationConfig = await this.getOrganizationConfig();

			// If org is defined try to get organization application highlights
			if (organizationConfig?.applicationsList) {
				try {
					applicationsListResponse = await getAssetByPath(organizationConfig?.applicationsList);
					unvalidatedApplicationsList = applicationsListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Application Highlights Config");
				}
			}

			// if org not defined or org application highlights not defined,
			// but atomic network is defined try to get atomic network application highlights
			const currentUserPrimaryAtomicNetwork = await authUserStore.getCurrentUserPrimaryAtomicNetwork();

			if (
				(!unvalidatedApplicationsList || unvalidatedApplicationsList?.length == 0) &&
				currentUserPrimaryAtomicNetwork
			) {
				try {
					applicationsListResponse = await getApplicationsByNetwork(currentUserPrimaryAtomicNetwork);
					unvalidatedApplicationsList = applicationsListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Application Highlights Config");
				}
			}

			// if we still dont have a application highlights list, try to fetch the default list
			if (!unvalidatedApplicationsList || unvalidatedApplicationsList?.length == 0) {
				try {
					applicationsListResponse = await getApplicationsByNetwork("default");
					unvalidatedApplicationsList = applicationsListResponse.data;
				} catch (error) {
					monitoringCaptureError(error, "Fetch Application Highlights Config");
				}
			}

			// Validate the application highlights returned from the assets server
			if (unvalidatedApplicationsList?.length > 0) {
				const validatedApplicationsList = unvalidatedApplicationsList.filter(
					(v: Partial<ApplicationSlideInfo>, idx: number) => {
						return this.validApplicationHighlightEntry(v);
					}
				);
				if (validatedApplicationsList.length > 0) {
					this.applicationsList = validatedApplicationsList;
				}
			} else {
				this.applicationsList = defaultApplicationsList;
			}
		},
		validSpotlightEntry(slideInfo: Partial<SpotlightSlideInfo>): boolean {
			// Ensure that there is a fallback image url if a video link is provided
			if (slideInfo?.videoURL) {
				// FIXME: potentially throws .videoURL.includes is not a function
				// if the video isnt from vimeo or if the fallback image is not provided...
				if (!slideInfo.videoURL.includes("vimeo.com") && !slideInfo.imageURL) {
					return false;
				}
			}

			switch (slideInfo.type) {
				case "application":
					return slideInfo?.titleText &&
						(slideInfo?.imageURL || slideInfo?.videoURL) &&
						slideInfo?.applicationURL
						? true
						: false;
				default:
					return slideInfo?.titleText && (slideInfo?.imageURL || slideInfo?.videoURL) ? true : false;
			}
		},
		validApplicationHighlightEntry(slideInfo: Partial<ApplicationSlideInfo>): boolean {
			const requiredKeys = ["name", "tags", "applicationURL"];
			// Check if any of the keys in the required keys list does not exist in the object
			return !requiredKeys.some((k) => {
				!Object.prototype.hasOwnProperty.call(slideInfo, k);
			});
		},

		//* Code Languages
		setCodeLanguagesList(codeLanguagesList: SourceCodeLanguageObject[]) {
			this.codeLanguagesList = codeLanguagesList;
		},
		setupListenerForOnlineStatus() {
			const setNetworkState = (e) => {
				this.platformNetworkOnline = e.type === "online";
			};
			window.addEventListener("offline", setNetworkState);
			window.addEventListener("online", setNetworkState);
		},
	},
});
