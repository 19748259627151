import type { EventBusKey } from "@vueuse/core";
import { UserLimits } from "@/js/tierconfig";

export const TierLimitEventTypeE = {
	LimitExceeded: "limit-exceeded",
	LimitNearThreshold: "limit-near-threshold",
	LimitInfo: "limit-info",
	UsageChanged: "usage-changed",
} as const;
type TierLimitEventType = (typeof TierLimitEventTypeE)[keyof typeof TierLimitEventTypeE];
export type TierLimitBusEvent = { type: TierLimitEventType; affectedLimits: UserLimits[] };
export const tierLimitEventKey: EventBusKey<TierLimitBusEvent> = Symbol("symbol-key");
