import { CoreSpecs } from "./cores";
import { DataSource } from "./dataSources";
import { Repository } from "./repositories";
import { SourceCode } from "./sourceCode";

export const BuildStatusE = {
	Initialising: "Initialising",
	Accepted: "Accepted",
	InProgress: "In Progress",
	Completed: "Completed",
	Cancelled: "Cancelled",
	Stopped: "Stopped",
	Rescheduled: "Rescheduled",
	// These following statuses do not map 1:1 with SCCE build statuses.
	Error: "Error",
	TierLimited: "Tier Limit Reached",
} as const;

export type BuildStatus = (typeof BuildStatusE)[keyof typeof BuildStatusE];

export const BuildTypeE = {
	SourceCode: "SourceCode",
	Repository: "Repository",
	Pipeline: "Pipeline",
	Module: "Module",
} as const;
export type BuildType = (typeof BuildTypeE)[keyof typeof BuildTypeE];

export type BuildStatusTransition = {
	Status: BuildStatus;
	Timestamp: number;
	Message?: string;
};

export function isBuildActive(BuildStatus: BuildStatus): boolean {
	const activeStatuses = [
		BuildStatusE.Accepted,
		BuildStatusE.Initialising,
		BuildStatusE.InProgress,
		BuildStatusE.Rescheduled,
	];
	return activeStatuses.some((s) => s === BuildStatus);
}

export function isBuildTerminated(BuildStatus: BuildStatus): boolean {
	const activeStatuses = [BuildStatusE.Completed, BuildStatusE.Cancelled, BuildStatusE.Stopped];
	return activeStatuses.some((s) => s === BuildStatus);
}

export function isBuildSuccess(BuildStatus: BuildStatus): boolean {
	const activeStatuses = [BuildStatusE.Completed];
	return activeStatuses.some((s) => s === BuildStatus);
}
export function isBuildFail(BuildStatus: BuildStatus): boolean {
	const activeStatuses = [BuildStatusE.Cancelled, BuildStatusE.Stopped];
	return activeStatuses.some((s) => s === BuildStatus);
}

export function canFetchVariables(BuildStatus: BuildStatus): boolean {
	// If the Build is in any of the following states, we can possibly fetch variables
	const statusesToCheckFor = [BuildStatusE.Completed];

	return statusesToCheckFor.some((s) => s === BuildStatus) ?? false;
}

export type BuildStatistics = {
	BuildTimeInMilliseconds: number;
	BuildSizeInBytes: number;
};

export type Build = {
	BuildID: string;
	Application: Repository | SourceCode;
	Owner: string;
	Status: BuildStatus;
	BuildCoreSpecs: CoreSpecs;
	DefaultArguments: string;
	DefaultDataSources: DataSource[];
	StatusTransitions: BuildStatusTransition[];
	StartedAt: number /* The time the Build.Status becomes "In Progress" */;
	UpdatedAt: number;
	CreatedAt: number /* The time the Build.Status becomes "Accepted" */;
	FinishedAt: number /* The time the Build.Status becomes "Completed", "Cancelled", or "Stopped" */;
};

export function isRepositoryBuild(build: Build): boolean {
	return "RepositoryID" in build.Application && "RemoteURL" in build.Application;
}

export function isSourceCodeBuild(build: Build): boolean {
	return "Code" in build.Application && "Language" in build.Application;
}

export type BuildWithStats = {
	BuildID: string;
	Owner: string;
	Status: BuildStatus;
	StatusTransitions: BuildStatusTransition[];
	Stats?: BuildStatistics;
	StartedAt: number /* The time the Build.Status becomes "In Progress" */;
	UpdatedAt: number;
	CreatedAt: number /* The time the Build.Status becomes "Accepted" */;
	FinishedAt: number /* The time the Build.Status becomes "Completed", "Cancelled", or "Stopped" */;
};

export const BuildOutputStreamsE = {
	build: "build",
} as const;
export type BuildOutputStreams = (typeof BuildOutputStreamsE)[keyof typeof BuildOutputStreamsE];
