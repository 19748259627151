type dbCore = {
	Object: "Core";
	CoreID: string;
	Owner: string;
	Name: string;
	Class: string;
	Precision: number | undefined;
	MemorySize: number;
	Microarchitecture: CoreMicroarchitecture;
	CorrelationTracking: string;
	CreatedAt: number;
	UpdatedAt: number;
};
export type CoreSpecs = {
	Class: string;
	Precision: number;
	MemorySize: number;
	Microarchitecture: string;
	CorrelationTracking: string;
};

export type CoreID = string;
export type Core = dbCore & {
	Immutable: boolean;
	Disabled: boolean;
	Hidden: boolean;
};

export type CreateCoreConfig = Pick<
	Core,
	"Name" | "Class" | "Precision" | "MemorySize" | "Microarchitecture" | "CorrelationTracking"
>;

export const CoreCorrelationTypeE = {
	Disabled: "Disabled",
	Autocorrelation: "Autocorrelation",
} as const;
export type CoreCorrelationType = (typeof CoreCorrelationTypeE)[keyof typeof CoreCorrelationTypeE];

export const CoreCorrelationTypeCodeE = {
	Disabled: "Disable",
	Autocorrelation: "Autocorrelation",
} as const;
export type CoreCorrelationTypeCode = (typeof CoreCorrelationTypeCodeE)[keyof typeof CoreCorrelationTypeCodeE];

export const CoreClassE = {
	C0: "C0",
	C0Pro: "C0Pro",
} as const;
export type CoreClass = (typeof CoreClassE)[keyof typeof CoreClassE];

export const CoreMicroarchitectureE = {
	Zurich: "Zurich",
	Athens: "Athens",
	Mars: "Mars",
	Saturn: "Saturn",
	Reference: "Reference",
	Jupiter: "Jupiter",
	Bypass: "Bypass",
} as const;
export type CoreMicroarchitecture = (typeof CoreMicroarchitectureE)[keyof typeof CoreMicroarchitectureE];

export type CoreMicroarchitectureObject = {
	ID: number;
	Name: CoreMicroarchitecture;
	Support: {
		CorrelationTracking: string[];
		CustomPrecision: boolean;
		DistributionTracking: boolean;
	};
	Disabled: boolean;
	Experimental: boolean;
	Restricted: boolean;
};

export type CoreClassObject = {
	Name: CoreClass;
	Enabled: boolean;
	Experimental?: boolean;
	SupportedMicroarchitectures: string[];
};

export type CoreCorrelationTypeObject = {
	Name: CoreCorrelationType;
	Code: CoreCorrelationTypeCode;
	Experimental: boolean;
	SupportedMicroarchitectures: string[];
};

export type getUserCoresResponse = {
	UserID: string;
	Cores: Core[];
	Count: number;
};

export type CoreRequirementMatch = {
	[Property in keyof Core]: boolean;
};
