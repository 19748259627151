import { defineStore } from "pinia";
import { Auth } from "aws-amplify";

import posthog from "posthog-js";

import {
	tiers,
	userGroupFromTokenPayload,
	groupCode,
	CognitoAccountGroup,
	AccountGroupCode,
	AccountTierDetail,
} from "@/js/tierconfig";
import * as signaloidClient from "@/js/signaloidClient";
import {
	AWSAuthenticatedSession,
	AWSAuthenticatedUserObject,
	CurrentUser,
	User,
	UserCustomization,
	UserPreferences,
} from "@/types/user";

import { CognitoUserSession } from "amazon-cognito-identity-js";
import { SubscriptionConfig } from "@/types/api/subscriptions";
import { monitoringCaptureError } from "@/plugins/monitoring";
import axios from "axios";

type State = {
	currentAuthenticatedUserObject: undefined | null | AWSAuthenticatedUserObject;
	currentUserObjectFromDB: undefined | User;
	currentUserSessionObject: undefined | null | AWSAuthenticatedSession;
	currentUserCustomizations: undefined | null | UserCustomization;
	currentUserTierCode: undefined | "" | AccountGroupCode;
	currentUserTierDetails: undefined | AccountTierDetail;
	currentUserSubscriptions: undefined | SubscriptionConfig;
};

export const useUserStore = defineStore("user", {
	state: (): State => ({
		currentAuthenticatedUserObject: undefined,
		currentUserObjectFromDB: undefined,
		currentUserSessionObject: undefined,
		currentUserCustomizations: undefined,
		currentUserTierCode: undefined,
		currentUserTierDetails: undefined,
		currentUserSubscriptions: undefined,
	}),
	getters: {
		currentUser(state): undefined | CurrentUser {
			//! deprecated
			if (!state.currentAuthenticatedUserObject) {
				return undefined;
			}
			return {
				email: state.currentAuthenticatedUserObject?.attributes.email,
				name: state.currentAuthenticatedUserObject?.attributes.name,
				affiliation: state.currentAuthenticatedUserObject?.attributes?.["custom:affiliation"],
				userID: state.currentAuthenticatedUserObject?.attributes?.["custom:user_id"],
				avatar: "",
			};
		},
		currentUserResourceUsage(state) {
			return state.currentUserObjectFromDB?.ResourceUsage;
		},
		currentUserPrimaryAtomicNetwork: (state) => {
			if (!state.currentUserCustomizations?.AtomicNetworks) {
				return "default";
			}

			return state.currentUserCustomizations?.AtomicNetworks?.length > 0
				? state.currentUserCustomizations?.AtomicNetworks[0]
				: "default";
		},
		currentUserPrimaryOrganization: (state): string | null => {
			if (!state.currentUserCustomizations?.Organizations) {
				return null;
			}
			return state.currentUserCustomizations?.Organizations?.length > 0
				? state.currentUserCustomizations?.Organizations[0]
				: null;
		},
	},
	actions: {
		async refreshCurrentUserSession() {
			// initialise `this.currentAuthenticatedUserObject`
			await this.fetchCurrentUserFromAWS();

			// initialise `this.currentUserSessionObject`
			await this.fetchCurrentUserSessionFromAWS();

			if (!this.currentUserSessionObject) {
				this.currentUserSessionObject = await Auth.currentSession();
			}

			/*
			 *  `CognitoUser.refreshSession()` doesn't return a promise so
			 *  we have to make one on the spot.
			 */
			const newSession = await new Promise<CognitoUserSession>((resolve, reject) => {
				this.currentAuthenticatedUserObject.refreshSession(
					// @ts-ignore this.currentUserSessionObject should be defined at this point
					this.currentUserSessionObject.getRefreshToken(),
					() => {
						Auth.currentSession().then(resolve).catch(reject);
					}
				);
			});
			if (newSession) {
				this.currentUserSessionObject = newSession;
			}
			await this.registerCurrentUserTokens();

			return this.currentUserSessionObject;
		},
		async registerCurrentUserTokens() {
			// This can be streamlined to
			signaloidClient.registerTokens(
				Auth.currentSession().then((sess) => {
					return sess.getAccessToken().getJwtToken();
				}),
				Auth.currentSession().then((sess) => sess.getIdToken().getJwtToken())
			);

			signaloidClient.registerRefreshTokenCallbacks(
				async () => {
					const currentSession = await Auth.currentSession();
					return currentSession.getAccessToken();
				},
				async () => {
					const currentSession = await Auth.currentSession();
					return currentSession.getIdToken();
				}
			);
		},

		/*
		 * ======================================================
		 * Async functions for getting user data with dependencies
		 */

		async getCurrentAuthenticatedUserObject() {
			if (this.currentAuthenticatedUserObject === undefined) {
				this.fetchCurrentUserFromAWS();
			}

			return new Promise<null | AWSAuthenticatedUserObject>((resolve, reject) => {
				const loop = () =>
					this.currentAuthenticatedUserObject !== undefined
						? resolve(this.currentAuthenticatedUserObject)
						: setTimeout(loop);
				loop();
			});
		},

		async getCurrentUserID() {
			if (this.currentAuthenticatedUserObject === undefined) {
				this.getCurrentAuthenticatedUserObject();
			}
			return new Promise<string>((resolve, reject) => {
				const loop = () =>
					this.currentAuthenticatedUserObject?.attributes !== undefined
						? resolve(
								this.currentAuthenticatedUserObject.attributes?.["custom:user_id"] ??
									`usr_${this.currentAuthenticatedUserObject.attributes.sub.replace(/-/g, "")}`
						  )
						: setTimeout(loop);
				loop();
			});
		},

		async getCurrentUserSubscriptions() {
			if (this.currentUserSubscriptions === undefined) {
				await this.fetchCurrentUserSubscriptions();
			}

			return new Promise<null | SubscriptionConfig>((resolve, reject) => {
				const loop = () =>
					this.currentUserSubscriptions !== undefined
						? resolve(this.currentUserSubscriptions)
						: setTimeout(loop);
				loop();
			});
		},
		async getCurrentUserTierDetails() {
			const tierCode = await this.getCurrentUserTierCode();
			this.currentUserTierDetails = tiers.find((e) => e.code === tierCode);
			return this.currentUserTierDetails;
		},

		async getCurrentUserTierCode() {
			if (this.currentUserSubscriptions === undefined) {
				await this.fetchCurrentUserSubscriptions();
			}

			return new Promise<"" | AccountGroupCode>((resolve, reject) => {
				const loop = () => {
					if (this.currentUserSubscriptions !== undefined) {
						this.currentUserTierCode = groupCode(this.currentUserSubscriptions?.Tier);
						resolve(this.currentUserTierCode);
					} else {
						setTimeout(loop);
					}
				};
				loop();
			});
		},

		async getCurrentUserGroups() {
			if (this.currentUserSessionObject === undefined) {
				this.fetchCurrentUserSessionFromAWS();
			}
			return new Promise<null | CognitoAccountGroup[]>((resolve, reject) => {
				const loop = () => {
					if (this.currentUserSessionObject !== undefined) {
						if (this.currentUserSessionObject === null) {
							return resolve(null);
						} else {
							resolve(this.currentUserSessionObject.getAccessToken()?.payload["cognito:groups"] ?? null);
						}
					} else {
						setTimeout(loop);
					}
				};
				loop();
			});
		},
		async getCurrentUserPreferences() {
			return new Promise<Partial<UserPreferences>>((resolve, reject) => {
				const loop = () =>
					this.currentUserObjectFromDB !== undefined
						? resolve(this.currentUserObjectFromDB?.Preferences)
						: setTimeout(loop);
				loop();
			});
		},
		async getCurrentUserCustomizations() {
			if (this.currentUserCustomizations === undefined) {
				this.fetchCurrentUserCustomizationsFromDB();
			}
			return new Promise<null | Partial<UserCustomization>>((resolve, reject) => {
				const loop = () =>
					this.currentUserCustomizations !== undefined
						? resolve(this.currentUserCustomizations)
						: setTimeout(loop);
				loop();
			});
		},
		async getCurrentUserPrimaryOrganization() {
			if (this.currentUserCustomizations === undefined) {
				this.fetchCurrentUserCustomizationsFromDB();
			}
			return new Promise<null | string>((resolve, reject) => {
				const loop = () =>
					this.currentUserCustomizations !== undefined
						? resolve(this.currentUserPrimaryOrganization)
						: setTimeout(loop);
				loop();
			});
		},
		async getCurrentUserPrimaryAtomicNetwork() {
			if (this.currentUserCustomizations === undefined) {
				this.fetchCurrentUserCustomizationsFromDB();
			}
			return new Promise<null | string>((resolve, reject) => {
				const loop = () =>
					this.currentUserCustomizations !== undefined
						? resolve(this.currentUserPrimaryAtomicNetwork)
						: setTimeout(loop);
				loop();
			});
		},

		/*
		 * ======================================================
		 * Functions for fetching user data from remote
		 */

		async fetchCurrentUserFromAWS() {
			try {
				const user = await Auth.currentAuthenticatedUser();
				this.currentAuthenticatedUserObject = user;
			} catch (error) {
				console.warn("🚀 ~ file: user.ts:272 ~ fetchCurrentUserFromAWS ~ error:", error);
			}
		},

		async fetchCurrentUserCustomizationsFromDB() {
			try {
				const currentUserID = await this.getCurrentUserID();
				// if for some reason currentUserID is still not available
				if (!currentUserID) {
					throw new Error("User ID not found");
				}

				const response = await signaloidClient.getUserCustomizations(currentUserID);
				this.currentUserCustomizations = response.data;

				if (this.currentUserCustomizations.Organizations) {
					posthog?.setPersonProperties({
						organizations: this.currentUserCustomizations.Organizations,
					});
				}

				if (this.currentUserCustomizations.AtomicNetworks) {
					posthog?.setPersonProperties({
						atomicNetworks: this.currentUserCustomizations.AtomicNetworks,
					});
				}
			} catch (error) {
				this.currentUserCustomizations = null;

				if (!axios.isAxiosError(error)) {
					monitoringCaptureError(error, "Fetch user customisations from DB");
					return;
				}
				// We do not send to sentry 404 to reduce noise
				const isNotFoundError = error.response?.status === 404;
				if (!isNotFoundError) {
					// Send to sentry otherwise
					monitoringCaptureError(error, "Fetch user customisations from DB");
				}
			}
		},

		async fetchCurrentUserObjectFromDB() {
			try {
				const currentUserID = await this.getCurrentUserID();
				// if for some reason currentUserID is still not available
				if (!currentUserID) {
					throw new Error("User ID not found");
				}

				const response = await signaloidClient.getUserByID(currentUserID);
				this.currentUserObjectFromDB = response.data;
			} catch (error) {
				monitoringCaptureError(error, "Fetch user object from DB");
				console.log("Error fetching user data from DB :>> ", error);
				throw error;
			}
		},
		async fetchCurrentUserSubscriptions() {
			try {
				const currentUserID = await this.getCurrentUserID();
				// if for some reason currentUserID is still not available
				if (!currentUserID) {
					throw new Error("User ID not found");
				}

				const response = await signaloidClient.getUserSubscriptions();

				this.currentUserSubscriptions = response.data.subscription;
			} catch (error) {
				/*
				 *	We only catch this here to capture it in monitoring. We will
				 *	not handle this exception here.
				 */
				monitoringCaptureError(error, "Fetch user subscription");
				console.log("Error fetching user subscriptions :>> ", error);
				// re-throw error
				throw error;
			}
		},
		async fetchCurrentUserSessionFromAWS() {
			const session = await Auth.currentSession();
			this.currentUserSessionObject = session;
		},
		async syncUserPreferenceWithRemote(newPreferences: Partial<UserPreferences>) {
			try {
				const currentUserID = await this.getCurrentUserID();
				if (!currentUserID) {
					throw new Error("User ID not found");
				}
				const newUserPreferencesData = await signaloidClient.updateUserPreferences(
					currentUserID,
					newPreferences
				);
				const newUserPreferences = newUserPreferencesData.data.Preferences as UserPreferences

				if (this.currentUserObjectFromDB) {
					this.currentUserObjectFromDB.Preferences = {
						...this.currentUserObjectFromDB.Preferences,
						...newUserPreferences
					};
				} 
			
			} catch (error) {
				monitoringCaptureError(error, "Sync user preferences with DB");
			}
		},
		async startFreeTrial() {
			try {
				const response = await signaloidClient.subscribeToFreeTrial("ProTier");
				this.currentUserSubscriptions = response.data.subscription;
			} catch (error) {
				monitoringCaptureError(error, "Start Free Trial");
				console.log("Error starting free trial :>> ", error);
				throw error;
			}
		},
	},
});
