import userflow from "userflow.js";
import { Auth } from "aws-amplify";
import { AWSAuthenticatedUserObject } from "@/types/user";
import vuetify from "@/plugins/vuetify";
import { userGroupFromTokenPayload } from "@/js/tierconfig";
import { monitoringForgetUser, monitoringIdentifyUser } from "./monitoring";
import posthog from "posthog-js";

export async function identifyAnalyticsUser() {
	const user: null | AWSAuthenticatedUserObject = await Auth.currentAuthenticatedUser();

	if (user) {
		const userID = user.attributes?.["custom:user_id"] ?? `usr_${user.attributes.sub.replace(/-/g, "")}`;
		const groups = await user.getSignInUserSession().getAccessToken().payload["cognito:groups"];
		const userTier = userGroupFromTokenPayload(groups);

		if (userID) {
			const userEmail =
				user?.attributes.email && user?.attributes.email.includes("@signaloid.com")
					? user?.attributes.email
					: undefined;

			const userName = undefined;

			userflow?.identify(userID, {
				name: userName,
				email: userEmail,
				userTier: userTier,
				device_type: vuetify.framework.breakpoint.mobile ? "mobile" : "desktop",
			});

			posthog?.identify(userID, {
				email: userEmail,
				userTier: userTier,
				release: `${process.env.VUE_APP_VERSION}+${
					process.env.VUE_APP_COMMIT_HASH ? process.env.VUE_APP_COMMIT_HASH.slice(0, 7) : "_"
				}`,
			});

			monitoringIdentifyUser(userID, userGroupFromTokenPayload(groups), userEmail);
		}
	}
}

export async function forgetAnalyticsUser() {
	userflow?.reset();
	globalThis.analytics?.reset();
	posthog?.reset();
	monitoringForgetUser();
}
