// This file contains deprecated functions currently only used by the pipelines feature

import { monitoringCaptureError } from "@/plugins/monitoring";
import axios, { AxiosResponse } from "axios";
import { backendClient } from "../signaloidClient";

// * Deprecated
// TODO: change this to accept execution request object and do the stringify inside
export function postJobToServer(requestData: string) {
	return backendClient.post("/projects", requestData, {
		headers: {
			"Content-Type": "application/json",
		},
	});
}

// * Deprecated
export function getProjectByID(projectID: string) {
	return backendClient.get(`/projects/${projectID}`, {
		headers: {
			"Cache-Control": "max-age=0, no-store",
		},
		timeout: 0,
	});
}

// * Deprecated
export function getProjectByID_LongPolling(projectID: string) {
	return backendClient.get(`/projects/${projectID}`, {
		headers: {
			"Long-Polling": "30",
			"Cache-Control": "max-age=0, no-store",
		},
		timeout: 0 /* Setting a timeout other than `0` here makes long-polling fail */,
	});
}

// * Deprecated
/**
 * This function might invoke the callback more than once.
 *
 * @param {string} projectID
 * @param {string} taskID
 * @param {string} backendURL
 * @param {(string)=>(any)} handleResultsCallback
 */
export async function subscribeToProject(
	projectID: string,
	taskID: string,
	backendURL: string,
	handleResultsCallback: CallableFunction
) {
	/*
	 *  Note: This function might invoke the callback more than once.
	 */

	/*
	 *  Get whatever info is available right now.
	 */
	let projectStatus = "In progress";

	try {
		const resp = await backendClient.get(backendURL + `/projects/${projectID}`, {
			headers: {
				"Cache-Control": "max-age=0, no-store",
			},
			timeout: 0,
		});
		if ("Status" in resp.data) {
			projectStatus = resp.data.Status;
		} else if ("build error" in resp.data) {
			projectStatus = "Build Error";
		}

		resp.data.ProjectID = projectID;
		resp.data.TaskID = taskID;
		handleResultsCallback(resp.data);
	} catch (error) {
		monitoringCaptureError(error, "[Deprecated] Subscribe to project and handle outputs )");
	}

	/*
	 *  Subscribe until project completion.
	 */
	while (projectStatus === "In progress") {
		try {
			const resp = await backendClient.get(backendURL + `/projects/${projectID}`, {
				headers: {
					"Long-Polling": "30",
					"Cache-Control": "max-age=0, no-store",
				},
				timeout: 0 /* Setting a timeout here makes long-polling fail */,
			});
			// console.log(resp);
			if ("Status" in resp.data) {
				projectStatus = resp.data.Status;
			} else if ("build error" in resp.data) {
				projectStatus = "Build Error";
			} else {
				// TODO: What if both are missing?
			}

			resp.data.ProjectID = projectID;
			resp.data.TaskID = taskID;
			handleResultsCallback(resp.data);
			/*
			 *	Do not break here. If projectStatus is not "In Progress" the
			 *	loop will exit.
			 */
		} catch (error) {
			monitoringCaptureError(error, "[Deprecated] Subscribe to project and handle outputs )");
			/*
			 *  If error is a timeout we try again
			 */
			if (axios.isAxiosError(error)) {
				if (error.response !== undefined && error.response.status === 408) {
					console.log("Timed out waiting for a response... retrying");
					continue;
				} else {
					console.error(error);
					break;
				}
			}
		}
	}
}

/**
 * Cancel an active task.
 *
 * @param {string} projectID The ID of the task.
 * @returns {Promise<AxiosResponse<any>>} The response from the server.
 */
export function cancelActiveTask(projectID: string): Promise<AxiosResponse<any>> {
	return backendClient.delete(`/projects/${projectID}`, {
		headers: {
			"Cache-Control": "no-cache",
			// 'Pragma': 'no-cache',
			// 'Expires': '0',
		},
	});
}
