//./plugins/posthog.js
import posthog from "posthog-js";

const posthogKey = process.env.VUE_APP_POSTHOG_TOKEN;
const posthogInstanceAddress = process.env.VUE_APP_POSTHOG_INSTANCE_ADDRESS;

export default {
	install(Vue, options) {
		if (posthogKey && posthogInstanceAddress) {
			Vue.prototype.$posthog = posthog.init(posthogKey, {
				api_host: posthogInstanceAddress,
				autocapture: {
					dom_event_allowlist: ["click", "submit"], // DOM events from this list ['click', 'change', 'submit']
					element_allowlist: ["button"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
				},
			});
		}
	},
};
