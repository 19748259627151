// Libraries
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import {
	CreatePipeline201Response,
	EditPipeline200Response,
	GetPipeline200Response,
	ListPipelines200Response,
	PipelineRequestConfig,
} from "../signaloidClient.types";

/**
 * Get the list of pipelines in the account
 *
 * @returns {Promise<AxiosResponse<ListPipelines200Response>>} A promise that resolves to a "200 OK" response along with an object
 * containing a list of pipeline objects for each user-created pipelines.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Pipelines/operation/GetPipelines
 *
 */
export function getUserPipelines(): Promise<AxiosResponse<ListPipelines200Response>> {
	return signaloidApiClient.get(`/pipelines`);
}

/**
 * Create a new pipeline in the account.
 *
 * @param {PipelineRequestConfig} pipelineConfig The configuration of the pipeline to be created.
 * @returns {Promise<AxiosResponse<CreatePipeline201Response>>} A promise that resolves to a "200 OK" or "201 OK" response if
 * a pipeline is successfully created, along with a pipeline object with
 * the pipeline data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Pipelines/operation/CreatePipeline
 */
export function createPipeline(
	pipelineConfig: PipelineRequestConfig
): Promise<AxiosResponse<CreatePipeline201Response>> {
	return signaloidApiClient.post(`/pipelines`, pipelineConfig);
}

/**
 * Get a pipeline resource by PipelineID.
 *
 * @param {string} PipelineID The identifier of the pipeline.
 * @returns {Promise<AxiosResponse<GetPipeline200Response>>} A promise that resolves to a "200 OK" along with a
 * pipeline object with the pipeline data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Pipelines/operation/GetPipeline
 */
export function getPipelineByID(PipelineID: string): Promise<AxiosResponse<GetPipeline200Response>> {
	return signaloidApiClient.get(`/pipelines/${PipelineID}`);
}

/**
 * Delete a pipeline resource by PipelineID.
 *
 * @param {string} PipelineID The identifier of the pipeline.
 * @returns {Promise<AxiosResponse<void>>} The axios response promise.
 * If sucessful, the body resolves to:
 * ```json
 * { "message": "OK" }
 * ```
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Pipelines/operation/DeletePipeline
 */
export function deletePipelineByID(PipelineID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.delete(`/pipelines/${PipelineID}`);
}

/**
 * Update a pipeline resource by PipelineID. The request body must include all updatable fields.
 *
 * @param {string} PipelineID The identifier of the pipeline.
 * @param {Pipeline} pipelineConfig The configuration of the pipeline to be updated.
 * @returns {Promise<AxiosResponse<EditPipeline200Response>>} A promise that resolves to a "200 OK" response if pipeline
 * is successfully updated along with a pipeline object with the pipeline
 * data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Pipelines/operation/UpdatePipeline
 */
export function updatePipelineByID(
	PipelineID: string,
	pipelineConfig: PipelineRequestConfig
): Promise<AxiosResponse<EditPipeline200Response>> {
	return signaloidApiClient.put(`/pipelines/${PipelineID}`, pipelineConfig);
}
