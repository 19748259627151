// Libraries
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import {
	CreateRepository201Response,
	EditRepository200Response,
	GetRepository200Response,
	ListRepositories200Response,
	RepositoryConnectionRequestConfig,
} from "../signaloidClient.types";

//* REPOSITORIES

/**
 * Get the list of repositories in the account
 *
 * @returns {Promise<AxiosResponse<ListRepositories200Response>>} A promise that resolves to a "200 OK" response along with an object
 * containing a list of repository objects for each user-created repositories.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/GetConnectedRepositories
 */
export function getUserRepositories(): Promise<AxiosResponse<ListRepositories200Response>> {
	return signaloidApiClient.get(`/repositories`);
}

/**
 * Connect a new repository to the account.
 *
 * @param {RepositoryConnectionRequestConfig} repositoryConfig The configuration of the repository to be created.
 * @returns {Promise<AxiosResponse<CreateRepository201Response>>} A promise that resolves to a "200 OK" or "201 OK" response if
 * a repository is successfully connected, along with a repository object with
 * the repository data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/ConnectRepository
 */
export function addRepository(
	repositoryConfig: RepositoryConnectionRequestConfig
): Promise<AxiosResponse<CreateRepository201Response>> {
	return signaloidApiClient.post(`/repositories`, repositoryConfig);
}

/**
 * Get a connected repository resource by repositoryID
 *
 * @param {string} repositoryID The identifier of the repository.
 * @returns {Promise<AxiosResponse<GetRepository200Response>>} A promise that resolves to an object with the
 * repository details.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/GetRepository
 */
export function getRepositoryByID(repositoryID: string): Promise<AxiosResponse<GetRepository200Response>> {
	return signaloidApiClient.get(`/repositories/${repositoryID}`);
}

/**
 * Delete a connected Repository resource.
 *
 * @param {string} repositoryID The identifier of the repository.
 * @returns {Promise<AxiosResponse<void>>} The axios response promise.
 * If sucessful, the body resolves to:
 * ```json
 * { "message": "OK" }
 * ```
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/DeleteRepository
 */
export function deleteRepositoryByID(repositoryID: string): Promise<AxiosResponse<void>> {
	return signaloidApiClient.delete(`/repositories/${repositoryID}`);
}

/**
 * Edit a connected repository resource by repositoryID
 *
 * @param {string} repositoryID The identifier of the repository.
 * @param {RepositoryConnectionRequestConfig} repositoryConfig The configuration of the repository to be edited.
 * @returns {Promise<AxiosResponse<EditRepository200Response>>} A promise that resolves to a "200 OK" response if repository
 * is successfully updated along with a repository object with the repository
 * data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/UpdateRepository
 */
export function editRepositoryByID(
	repositoryID: string,
	repositoryConfig: Partial<RepositoryConnectionRequestConfig>
): Promise<AxiosResponse<EditRepository200Response>> {
	return signaloidApiClient.patch(`/repositories/${repositoryID}`, repositoryConfig);
}

/**
 * Remove a key of a repository resource by repositoryID
 *
 * @param {string} repositoryID The identifier of the repository.
 * @param {(keyof RepositoryConnectionRequestConfig)[]} keysToRemove An array of keys to remove
 * @returns {Promise<AxiosResponse<EditRepository200Response>>} A promise that resolves to a "200 OK" response if repository
 * is successfully updated along with a repository object with the repository
 * data from the DB.
 * @see https://docs.signaloid.io/docs/api/reference/#tag/Repositories/operation/UpdateRepository
 */
export function removeKeysInRepositoryObject(
	repositoryID: string,
	keysToRemove: (keyof RepositoryConnectionRequestConfig)[]
): Promise<AxiosResponse<EditRepository200Response>> {
	const queryParams = `remove=${keysToRemove.join("&remove=")}`;
	return signaloidApiClient.patch(`/repositories/${repositoryID}?${queryParams}`, {});
}
