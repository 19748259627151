// Libraries
import { AxiosResponse } from "axios";
// Clients
import { backendClient, signaloidApiClient } from "../signaloidClient";
// Types
import { CreateGithubCredentials201Response, GetGithubCredentials200Response } from "../signaloidClient.types";

// TODO: add documentation

export function getGithubCredentials(UserID: string): Promise<AxiosResponse<GetGithubCredentials200Response>> {
	return signaloidApiClient.get(`/users/${UserID}/integrations/github`);
}
export function deleteGithubCredentials(UserID: string): Promise<AxiosResponse<GetGithubCredentials200Response>> {
	return signaloidApiClient.delete(`/users/${UserID}/integrations/github`);
}
export function putGithubCredentials(
	UserID: string,
	GithubAuthCode: string
): Promise<AxiosResponse<CreateGithubCredentials201Response>> {
	return signaloidApiClient.put(`/users/${UserID}/integrations/github`, {
		GithubAuthCode: GithubAuthCode,
	});
}

