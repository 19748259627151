// Libraries
// Utilities
import { CognitoAccountGroup } from "@/js/tierconfig";
// Types
import { NewSubscriptionConfig } from "@/types/api/subscriptions";
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
import { GetUserSubscriptions200Response, PutUserSubscriptions200Response } from "../signaloidClient.types";

export function subscribeToFreeTier() {
	return putUserSubscription("FreeTier", false);
}

export function subscribeToFreeTrial(tier: CognitoAccountGroup) {
	return putUserSubscription(tier, true);
}

export function getUserSubscriptions(): Promise<AxiosResponse<GetUserSubscriptions200Response>> {
	return signaloidApiClient.get(`/subscriptions`);
}

export function putUserSubscription(
	tierCode: CognitoAccountGroup,
	freeTrial: boolean,
	referralCode?: string
): Promise<AxiosResponse<PutUserSubscriptions200Response>> {
	const newSubscription: NewSubscriptionConfig = {
		Tier: tierCode,
	};

	const queryParams = {};
	if (freeTrial) {
		queryParams["freeTrialOption"] = "true";
	}

	return signaloidApiClient.put(`/subscriptions`, newSubscription, { params: queryParams });
}
