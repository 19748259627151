// Libraries
import { AxiosResponse } from "axios";
// Clients
import { signaloidApiClient } from "../signaloidClient";
// Types
import { CreateKey201Response, ListKeys200Response } from "../signaloidClient.types";

export function postUserAPIKeys(Name: string, ValidUntil: number | null): Promise<AxiosResponse<CreateKey201Response>> {
	return signaloidApiClient.post(`/keys`, {
		Name,
		ValidUntil,
	});
}

export function getUserAPIKeys(): Promise<AxiosResponse<ListKeys200Response>> {
	return signaloidApiClient.get(`/keys`);
}

export function deleteUserAPIKeyByID(keyID: string): Promise<AxiosResponse<any>> {
	return signaloidApiClient.delete(`/keys/${keyID}`);
}

export function getTestAuth() {
	return signaloidApiClient.get("/auth-test");
}
